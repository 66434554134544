@font-face {
  font-family: Roboto-Black;
  src: url(./assets/fonts/Roboto-Black.ttf);
}

body {
  background-color: transparent !important;
  margin: 0;
  font-family: 'Roboto-Black';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
