.poll-question-container {
    margin-top:16px;
    /*width: 492.5px;*/
    /*height: 341.5px;*/
    border-radius: 22px;
    background-color: #FFFFFF;
    position: relative;
}

.poll-question {
    /*width: 378px;*/
    /*height: 58px;*/
    font-family: Roboto-Black;
    font-size: 35px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    text-align: left;
}

.poll-question-container .poll-question-header-container > *{
    text-align:left;
    margin-left:13px;
    margin-right:38px
}

.poll-count {
    text-align: left;
    width: 378px;
    color: #989898;
}

.poll-submit-actions {
    margin-top:10px;
    /*position:absolute;*/
    /*bottom:0;*/
    /*right: 17px;*/
}
