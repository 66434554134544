
.poll-choices-container>div, .poll-results-container>div {
    margin-top: 16px;
}
.poll-choices-container:first-child, .poll-results-container:first-child {
    margin-top: 0px;
}

.poll-choice-container, .poll-result-container{

    /*width: 439px;*/
    /*height: 53.5px;*/
    /*border-radius: 18.5px;*/
    height: 48px;
    background-color: #f1f1f1;
    color: #000000;
    margin: 10px auto auto;
}

.poll-choice, .poll-result {
    padding: 6.5px 29.5px 6px 21.5px;
    /*width: 388px;*/
    height: 41px;
    font-family: Roboto-Black;
    font-size: 17.5px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}

.poll-choice-selected {
    /*color: #FFFFFF;*/
    /*border-color: red;*/
    /*border-style: solid;*/
    /*border-width:1px;*/
}

.poll-result-answered {
    /*height: 53.5px;*/
    /*border-top-left-radius: 18.5px;*/
    /*border-bottom-left-radius: 18.5px;*/
    height: 48px;
    background-color: #2962ff;
}

.poll-result-container, .poll-result {
    position:relative;
    display:flex;
    align-items: center;
    z-index: 100;
}

.poll-result-answered{
    position:absolute;
    top:0;
    z-index:50;
}
.poll-result-percentage {
    z-index:100;
    margin-right:17.5px;
    margin-left: auto;
    align-self: center;
}

.poll-submit-container, .poll-next-container {
    width: 66px;
    height: 40px;
    border-radius: 14.5px;
    background-color: #989898;
    cursor: pointer;
}
.poll-next-container {
    background-color: #2962ff;
}

.poll-submit-container-enabled {
    background-color: #2962ff;
}

.poll-submit-container-submitting {
    background-color: #1C4088;
}

.poll-submit {
    padding:8.5px 11.5px 8px 12px;
    width: 42.5px;
    height: 23.5px;
    font-family: Roboto-Black;
    font-size: 20px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #FFFFFF;
}

.map-icon {
    border-radius:20px;
    width:21px;
    height:21px;
}